import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { FaMicrosoft } from "react-icons/fa";

const Shutters = () => (
  <React.Fragment>
    <Helmet>
      <title>Shutters | Melbourne | Brisbane | Birk</title>
      <meta
        property="og:title"
        content="Shutters | Melbourne | Brisbane | Birk"
      />
      <meta
        name="keywords"
        content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
      />
      <meta
        name="description"
        content="Birk offers range of quality Shutters. We are operated in Melbourne and Brisbane"
      />
      <meta
        property="og:description"
        content="Birk offers range of quality Shutters. We are operated in Melbourne and Brisbane"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://birk.itechnotion.com/shutters" />
      <link rel="canonical" href="http://birk.itechnotion.com/shutters" />
    </Helmet>
    <Header />
    <section className="product-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="heading">
              <h2>Shutters</h2>
            </div>
            <div className="product-menu">
              <ul>
                <li className="mb-2">
                  <Link to="/roller-blinds">Roller Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/roman-blinds">Roman Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/curtains">Curtains</Link>
                </li>
                <li className="mb-2">
                  <Link to="/shutters">
                    <span className="font-weight-bold">Shutters</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/venetians">Venetians</Link>
                </li>
                <li className="mb-2">
                  <Link to="/external-range">External Range</Link>
                </li>
                <li className="mb-2">
                  <Link to="/automation">Automation</Link>
                </li>
                <li className="mb-2">
                  <Link to="/honeycomb">Honeycomb</Link>
                </li>
              </ul>
              <div className="links-list sidebar-links">
                <Link to="/gallery" className="links-list-item">
                  <FaMicrosoft />
                  &nbsp; Go to gallery
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-lg-9 product-item mt-2">
            <div className="col-12 col-md-12 mb-4">
              <div className="pro-start-content">
                <div className="product-inner">
                  <p className="text-justify">
                    Often featured in resorts and hotels, shutters also make a
                    strong style statement at home. A window graced with a Birk
                    shutter is like a living room with a leather lounge; it’s a
                    place where style meets sophistication.
                  </p>
                  <p className="text-justify">
                    Shutters are enjoying a renaissance in the world of interior
                    design and Birk is delighted to lead the resurgence. Birk
                    shutters are defined by their attention to detail, from
                    sourcing premium hardwoods for manufacturing, right through
                    to installation. Our luxury louvers ensure minimal light
                    gaps and nestle comfortably when closed to offer added
                    insulation and privacy.
                  </p>
                  <p className="text-justify">
                    Although Birk shutters cater for a range of budgets, they
                    all come with a 3 year warranty, wear well and complement
                    your interiors. Thanks to a selection of over 50 stains and
                    colours, your Birk shutters sit effortlessly alongside the
                    palette of your patio or playroom.
                  </p>
                  <p className="text-justify mb-3">
                    For resale value, shutters are considered by consumers to be
                    a value add proposition, as they are a permanent fixture of
                    the home, whereas other forms of window coverings are
                    generally considered ' replaceable'.
                  </p>
                  <p>
                    Your Birk shutters can be customised in a range of
                    affordable and fashionable fabrics. Read more about our
                    Shutter ranges.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-sm-12 mt-2 mb-4">
              <div className="d-flex flex-wrap">
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/roman-icon2.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Motorisation</p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/shutters-icon2.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Shutters</p>
                </div>
              </div>
            </div>
            <div className="why-list why-list2 ml-4">
              <ul>
                <li>Suitable in all rooms</li>
                <li>UV, water & moisture resistant</li>
                <li>Recommended for wet areas</li>
                <li>Timeless</li>
              </ul>
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bshutters1.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bshutters2.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bshutters3.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </React.Fragment>
);
export default Shutters;
